import React, { useCallback, useLayoutEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomEditor from "../../components/editor/CustomEditor";
import { editInstruction, getInstruction } from "../../services/instruction";
import { Col, Container } from "react-bootstrap";
import Input from "../../components/UI/Input";
import DragDropFile from "../../components/DragDropFile";
import ImageCropper from "../../components/Cropper";
import { getImageURL } from "../../helpers/image";

const InstructionEdit = () => {
  const { instructionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const [isLoading, setIsLoading] = useState(false);
  const data = useWatch({ control });

  useLayoutEffect(() => {
    getInstruction(instructionId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback(() => {
    if (!data.title) {
      return NotificationManager.error("Введите заголовок")
    }

    setIsLoading(true);
    editInstruction(data)
      .then((res) => {
        NotificationManager.success("Документ успешно обновлен")
        setIsLoading(false);
      })
      .catch(
        (err) => {
          err && NotificationManager.error("Ошибка при сохранении документа")
          setIsLoading(false);
        }
      );
  }, [data]);
  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого документа нет</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title={data.title ?? "Документ"} />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/instructions"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>

          <Button onClick={() => onSubmit()} isLoading={isLoading} disabled={isLoading}>Сохранить изменения</Button>
        </div>
        <h3 className="mb-4">Редактирование инструкции</h3>
        <div className="mb-3">
          <DragDropFile
            title={
              !data.type || data.type == "instruction"
                ? "Выбрать видео"
                : "Выбрать изображение"
            }
            accept={
              !data.type || data.type == "instruction" ? "video/*" : false
            }
            multiple={false}
            onRemove={() => {
              setValue("file", null);
              setValue("media", null);
            }}
            file={data.media}
            onChange={(e) => {
              if (data.type === "instruction") {
                // Проверяем, что выбранный файл - это видео
                const file = e; // Предполагаем, что e - это массив файлов
                if (file && file.type.startsWith("video/")) {
                  setValue("file", file);
                  setValue("media", URL.createObjectURL(file));
                }
              } else {
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }));
              }
            }}
          />
          <ImageCropper
            file={editImageBanner.data[0]}
            show={editImageBanner.show}
            aspect={265 / 149}
            setShow={(e) =>
              setEditImageBanner((info) => ({ ...info, show: e }))
            }
            onComplete={(e) => {
              e && setValue("file", e.file);
              e && setValue("media", e.blob);
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Название"
            name="title"
            placeholder="Заголовок"
            errors={errors}
            defaultValue={data.title}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <Col md={12}>
          <div className="mb-3">
            <Input
              label="Ссылка"
              name="link"
              placeholder="Введите ссылку"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <CustomEditor
          content={data.desc}
          onChange={(e) => setValue("desc", e)}
        />
      </section>
    </Container>
  );
};

export default InstructionEdit;
